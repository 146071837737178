import styles from '@styles/components/Home/Banner.module.scss';
import dynamic from 'next/dynamic';
const Search = dynamic(() =>
  import('@components/pages/Home/Components/Search'),
);
import AdvanceSearch from '@components/AdvanceSearch';
export default function HomeBanner() {
  return (
    <section className={styles.bannerContainer} id="home-banner">
      <div className={styles.innerBanner}>
        <div className={`${styles.title} boldBodyText`}>
          Kênh thông tin về bất động sản
        </div>
        <div className={`${styles.subTitle}`}>
          Giúp bạn nhanh chóng tìm được ngôi nhà mà bạn luôn mong ước
        </div>
        <div className={`${styles.searchBarMobile} w-full h-40px`}>
          <Search />
        </div>
        <div className={`${styles.searchBarDesktop} w-full`}>
          <AdvanceSearch />
        </div>
      </div>
    </section>
  );
}
