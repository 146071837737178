import { useQuery } from '@apollo/client';
import { GET_REAL_ESTATE_BY_FIELD } from '@schemas/queries/realEstate';
import styles from '@styles/components/Home/Home.module.scss';
import dynamic from 'next/dynamic';
import { fieldGetRealEstate } from '@utils/configs/RealEstateConfiguarations/fieldGetRealEstateProject';
import { useRouter } from 'next/router';
import { routeSearchList } from '@utils/configs/SearchList/const';

const TitleNavigation = dynamic(() => import('@components/titleNavigation'));
const RealEstateCarousel = dynamic(() =>
  import('@components/pages/Home/Components/RealEstateCarousel'),
);
const SupperVip = dynamic(() => import('@public/svg/iconSupperVip.svg'));

export default function SupperVipRealEstate() {
  const { data: finalData } = useQuery(
    GET_REAL_ESTATE_BY_FIELD(fieldGetRealEstate),
    {
      variables: {
        page: 1,
        limit: 12,
        postType: ['1'],
        postStatus: ['active'],
        tradingStatus: ['trading'],
        isAuthorizedRe: false,
      },
    },
  );
  const router = useRouter();
  const items = finalData?.getRealEstates?.items || [];
  return (
    <div
      className={`${styles.session} ${items?.length > 0 ? 'flex' : 'hidden'}`}>
      <TitleNavigation
        title="Bất động sản VIP đặc biệt"
        Icon={SupperVip}
        showViewAll={items?.length > 0}
        onPressViewAll={() =>
          router.push(`/${routeSearchList}/ForSale/allRealEstateType/1`)
        }
      />
      <RealEstateCarousel data={items} />
    </div>
  );
}
