import styles from '@styles/components/Home/Home.module.scss';
import TitleNavigation from '@components/titleNavigation';
import IconVip from '@public/svg/iconVip.svg';
import CarouselV2 from '@components/CarouselV2';

import React from 'react';
import { toast } from 'react-toastify';
import MyToast from '@components/MyToast';
import { defaultToastOption } from '@utils/configs/Toast';
import { useRouter } from 'next/router';
import { useChatContext } from '@utils/context/chatContext';
import { useIsLogin } from '@hooks/auth/login/useIsLogin';
import { isMobile } from '@utils/helper';

type Props = {
  wrapperClass?: string,
};

const useData2 = () => {
  const router = useRouter();
  const { setShowChat, setShowChatMobile } = useChatContext();
  const isLogin = useIsLogin();
  return [
    {
      url: 'service_ban-do-qh.webp',
      onPress: async () => {
        if (!isLogin) {
          toast(
            <MyToast
              type="warning"
              message="Bạn cần đăng nhập để sử dụng chức năng này."
            />,
            defaultToastOption,
          );
        } else {
          await router.push('/ban-do?mapType=internal');
        }
      },
    },
    {
      url: 'service_bao-hiem.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_chat-video-call.webp',
      onPress: () => {
        if (!isLogin) {
          toast(
            <MyToast
              type="warning"
              message="Bạn cần đăng nhập để sử dụng chức năng này."
            />,
            defaultToastOption,
          );
        } else {
          if (isMobile) {
            setShowChatMobile(true);
          } else {
            setShowChat(true);
          }
        }
      },
    },
    {
      url: 'service_cskh.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_cty-xay-dung.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_data-bds.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_dinh-gia.webp',
      onPress: async () => {
        if (!isLogin) {
          toast(
            <MyToast
              type="warning"
              message="Bạn cần đăng nhập để sử dụng chức năng này."
            />,
            defaultToastOption,
          );
        } else {
          await router.push('/dinh-gia');
        }
      },
    },
    {
      url: 'service_luat-cong-chung.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_mua-sam.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_ngan-hang-vay.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_quan-ly-bds.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_quan-ly-toa-nha.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_smart-city.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_smart-home.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_vat-tu.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
    {
      url: 'service_ve-sinh-cn.webp',
      onPress: () => {
        toast(
          <MyToast type="warning" message="Chức năng đang được hoàn thiện" />,
          defaultToastOption,
        );
      },
    },
  ];
};

export default function ServiceOfRealTech({ wrapperClass }: Props) {
  const listServiceOfRealTeach = useData2();
  return (
    <div className={`${styles.session} ${wrapperClass}`}>
      <TitleNavigation
        title="Dịch vụ của RealTech247"
        Icon={IconVip}
        iconClassName="w-27px"
      />
      <div className={`w-full ${styles.realEstateCarousel}`}>
        <CarouselV2
          breakpoints={{
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 9,
            },
            1024: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 30,
            },
          }}
          showNavigation
          data={listServiceOfRealTeach}
          RenderItem={({ index, item }) => (
            <Ads onPress={item.onPress} key={index} url={item.url} />
          )}
        />
      </div>
    </div>
  );
}

type AdsProps = {
  url?: string,
  onPress?: Function,
};

function Ads({ url, onPress }: AdsProps) {
  return (
    <div onClick={onPress} className={styles.serviceOrRealTech}>
      <div
        className="flex w-full h-158px bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage: `url('/images/${url}')`,
        }}
      />
    </div>
  );
}
