import React from 'react';

export const HomeContext = React.createContext({
  authorizeData: {},
  vip1Data: {},
  vip2Data: {},
  projectData: {},
});

export const useHomeContext = () => React.useContext(HomeContext);
