import { options } from '@components/AdvanceSearch/configs';
import styles from '@styles/components/AdvanceSearch/TypeOfDemand.module.scss';

type TypeOfDemandComponentProps = {
  value?: number,
  setValue?: Function,
  wrapperClassName?: string,
  isPostedOriginal?: boolean,
  isPostedNeeded?: boolean,
};

export function TypeOfDemandComponent({
  value = 1,
  setValue,
  wrapperClassName,
  isPostedOriginal,
  isPostedNeeded,
}: TypeOfDemandComponentProps) {
  const finalConfigs = isPostedOriginal
    ? options?.slice(0, 2)
    : isPostedNeeded
    ? options?.slice(3, 5)
    : options;
  return (
    <div className={`${styles.typeOfDemandContainer} ${wrapperClassName}`}>
      {finalConfigs.map((item, index) => {
        const isActive = item.key === value;
        const lastItem = index === finalConfigs?.length - 1;
        return (
          <button
            key={index}
            onClick={() => setValue(item.key)}
            className={`flex flex-1 justify-center items-center h-full border-gray-300 ${
              isActive ? 'bg-blue-main' : 'bg-gray-50'
            } ${!lastItem ? 'border-r' : undefined}`}>
            <div
              className={`text-base font-bold ${
                isActive ? 'text-white' : 'text-black'
              }`}>
              {item.value}
            </div>
          </button>
        );
      })}
    </div>
  );
}
