import { useQuery } from '@apollo/client';
import { GET_REAL_ESTATE_BY_FIELD } from '@schemas/queries/realEstate';
import dynamic from 'next/dynamic';
import { fieldGetRealEstate } from '@utils/configs/RealEstateConfiguarations/fieldGetRealEstateProject';
import { useRouter } from 'next/router';
import { useHomeContext } from '@utils/context/homeContext';
import merge from 'lodash/merge';
import { routeSearchList } from '@utils/configs/SearchList/const';
const TitleNavigation = dynamic(() => import('@components/titleNavigation'));
const RealEstateCarousel = dynamic(() =>
  import('@components/pages/Home/Components/RealEstateCarousel'),
);
const Verify = dynamic(() => import('@public/svg/verify.svg'));

export default function AuthorizeRealEstate() {
  const { authorizeData } = useHomeContext();

  const { data: authorizedRe } = useQuery(
    GET_REAL_ESTATE_BY_FIELD(fieldGetRealEstate),
    {
      variables: {
        page: 1,
        limit: 12,
        isAuthorizedRe: true,
        postStatus: ['active'],
        tradingStatus: ['trading'],
      },
    },
  );

  const finalData = merge(authorizeData, authorizedRe) || {};

  const items = finalData?.getRealEstates?.items || [];

  const router = useRouter();
  return (
    <div className={`flex-col w-full ${items?.length > 0 ? 'flex' : 'hidden'}`}>
      <TitleNavigation
        title="Bất động sản chính chủ"
        Icon={Verify}
        showViewAll={items?.length > 0}
        onPressViewAll={async () =>
          await router.push(
            `/${routeSearchList}/ForSale/allRealEstateType/allPostType/?isAuthorizedRe=true`,
            undefined,
            { shallow: true },
          )
        }
      />
      <RealEstateCarousel data={items} />
    </div>
  );
}
