import React, { Fragment } from 'react';
import styles from '@styles/components/TitleNavigation.module.scss';
import dynamic from 'next/dynamic';
const ArrowBlue = dynamic(() => import('@public/svg/arrowDownBlue.svg'), {
  ssr: false,
});

type Props = {
  title?: string,
  Icon?: React.ComponentElement,
  iconClassName?: string,
  fill?: string,
  showViewAll?: boolean,
  onPressViewAll?: Function,
};

export default function TitleNavigation(props: Props) {
  const {
    title,
    Icon = Fragment,
    iconClassName,
    showViewAll,
    fill,
    onPressViewAll,
  } = props;
  return (
    <div className={styles.container}>
      <div className="flex flex-row">
        <div className={styles.badge}>
          {Icon && (
            <Icon
              fill={fill}
              className={`${styles.defaultSizeIcon} ${iconClassName}`}
            />
          )}
        </div>
      </div>
      <p className={styles.titleNav}>{title}</p>
      <button
        onClick={onPressViewAll}
        className={`flex-row cursor-pointer items-center mt-4px ${
          showViewAll ? 'flex' : 'hidden'
        }`}>
        <div className={`${styles.txtViewAll}`}>Xem tất cả</div>
        <ArrowBlue className={styles.arrowLeft} />
      </button>
    </div>
  );
}
