import SectionContainer from '@components/SectionContainer';
import HomeBanner from '@components/pages/Home/Components/HomeBanner';
import Promotion from '@components/pages/Home/Components/Promotion';
import AuthorizeRealEstate from '@components/pages/Home/Components/AuthorizeRealEstate';
import SupperVipRealEstate from '@components/pages/Home/Components/SupperVipRealEstate';
import Vip1RealEstate from '@components/pages/Home/Components/Vip1RealEstate';
import ServiceOfRealTech from '@components/pages/Home/Components/ServiceOfRealTech';
import Company from '@components/pages/Home/Components/Company';
import { useQuery } from '@apollo/client';
import dynamic from 'next/dynamic';
import styles from '@styles/components/Home/Home.module.scss';
import {
  GET_REPRESENTATIVE_BROKERS,
  LIST_COMPANY_BY_TYPE,
} from '@schemas/queries/users';
import Broker from '@components/pages/Home/Components/Broker';

const BannerAds = dynamic(
  () => import('@components/pages/Home/Components/BannerAds'),
  { ssr: false },
);
const BottomMenu = dynamic(() => import('@components/BottomMenu/BottomMenu'));
const SlideSearchBar = dynamic(
  () => import('@components/pages/Home/Components/SlideSearchBar'),
  { ssr: false },
);
const IconTeam = dynamic(() => import('@public/svg/team.svg'));
const Hanshake = dynamic(() => import('@public/svg/handshake.svg'));

const SubMenu = dynamic(
  () => import('@components/pages/Home/Components/SubMenu'),
  { ssr: false },
);

const OutstandingProject = dynamic(() =>
  import('./Components/OutstandingProject'),
);

export default function HomePage() {
  const { data } = useQuery(LIST_COMPANY_BY_TYPE, {
    variables: { type: 'estate', isPromoted: true, limit: 21 },
  });
  const { data: dataBroker } = useQuery(GET_REPRESENTATIVE_BROKERS, {
    variables: { limit: 12 },
  });

  return (
    <div className="flex w-full flex-col lg:pb-0 pb-20">
      <HomeBanner />
      <SectionContainer innerClassname={styles.innerHome}>
        <Promotion />
        <SubMenu />
        <div className="flex bg-white flex-col w-full mt-8px lg:mt-0 pt-24px lg:pt-0">
          <AuthorizeRealEstate />
          <ServiceOfRealTech />
          <SupperVipRealEstate />
          <Vip1RealEstate />
          <Company
            Icon={IconTeam}
            title="Doanh nghiệp tiêu biểu"
            data={data?.listCompaniesByType.companies}
          />
          <BannerAds />
          <OutstandingProject />
          <Company
            Icon={Hanshake}
            title="Đối tác chiến lược"
            data={data?.listCompaniesByType.companies}
          />
          <Broker data={dataBroker?.getRepresentativeBrokers} />
        </div>
      </SectionContainer>
      <SlideSearchBar />
      <BottomMenu />
    </div>
  );
}
