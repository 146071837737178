import { TypeOfDemandComponent } from '@components/AdvanceSearch/components/TypeOfDemand';
import { Controller, useFormContext } from 'react-hook-form';
import { convertTypeHomeToProject } from '@components/AdvanceSearch/configs';

export default function TypeOfDemandButton() {
  const { control, setValue } = useFormContext();
  return (
    <Controller
      control={control}
      name="isForSell"
      render={({ field: { onChange, value } }) => {
        return (
          <TypeOfDemandComponent
            setValue={(value) => {
              onChange(value);
              if (value === 'ForRent' || value === 'ToRent') {
                setValue('priceFrom', 0);
                setValue('priceTo', 200.5);
                setValue('priceKey', '');
                setValue('realEstateType', ['townhouse']);
              } else if (value === 'Project') {
                setValue('realEstateType', [...convertTypeHomeToProject]);
                setValue('priceFrom', 0);
                setValue('priceTo', 101);
                setValue('priceKey', '');
              } else {
                setValue('priceFrom', 0);
                setValue('priceTo', 101);
                setValue('priceKey', '');
                setValue('realEstateType', ['townhouse']);
              }
            }}
            value={value}
          />
        );
      }}
    />
  );
}
