import styles from '@styles/components/Home/Home.module.scss';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

const TitleNavigation = dynamic(() => import('@components/titleNavigation'));
const CarouselV2 = dynamic(() => import('@components/CarouselV2'));

const DefaultCompany = dynamic(() => import('@public/svg/defaultCompany.svg'));

type Props = {
  title?: string,
  Icon?: string,
  data?: Array,
  wrapperClass?: string,
};

export default function Company(props: Props) {
  const { title, Icon, data = [], wrapperClass } = props;
  const router = useRouter();
  return (
    <div
      className={`${styles.session} ${
        data?.length > 0 ? 'flex' : 'hidden'
      } ${wrapperClass}`}>
      <TitleNavigation
        title={title}
        Icon={Icon}
        iconClassName="w-27px"
        showViewAll={data?.length > 0}
        onPressViewAll={async () => await router.push('/404')}
      />
      <div className={`w-full ${styles.realEstateCarousel}`}>
        <CarouselV2
          breakpoints={{
            320: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 9,
            },
            1024: {
              slidesPerView: 6,
              slidesPerGroup: 6,
              spaceBetween: 30,
            },
          }}
          showNavigation
          data={data}
          RenderItem={({ index, item }) => (
            <ItemCompany
              onPress={() => alert('Chức năng đang được hoàn thiện')}
              key={index}
              {...item}
            />
          )}
        />
      </div>
    </div>
  );
}

type ItemCompanyProps = {
  logo?: Object,
  avatar?: Object,
  onPress?: Function,
};

function ItemCompany(props: ItemCompanyProps) {
  const { logo, avatar, onPress } = props;

  const finalAvatar = logo?.originalUrl || avatar?.originalUrl;

  return (
    <div onClick={onPress} className={`${styles.company}`}>
      <div
        style={{
          backgroundImage: `url('${finalAvatar}')`,
        }}
        className={`${
          finalAvatar?.length ? 'flex' : 'hidden'
        } w-full h-full rounded-rd16px bg-cover bg-no-repeat bg-center`}
      />
      <DefaultCompany
        className={`${
          finalAvatar?.length ? 'hidden' : 'flex'
        } w-full h-full bg-contain`}
      />
    </div>
  );
}
