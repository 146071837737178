import { getLayout } from '@components/Layout';
import HomePage from '@components/pages/Home';
import { addApolloState, initializeApollo } from '@utils/wire';
import {
  GET_REAL_ESTATE_BY_FIELD,
  GET_REAL_ESTATE_PROJECT_BY_FIELD,
} from '@schemas/queries/realEstate';
import Head from 'next/head';
import {
  GET_REPRESENTATIVE_BROKERS,
  LIST_COMPANY_BY_TYPE,
} from '@schemas/queries/users';

import {
  fieldsGetRealEstateProject,
  fieldGetRealEstateBasic,
} from '@utils/configs/RealEstateConfiguarations/fieldGetRealEstateProject';

import { HomeContext } from '@utils/context/homeContext';
import { NextSeo } from 'next-seo';

type Props = {
  authorizeData?: Object,
  vip1Data?: Object,
  vip2Data?: Object,
  projectData?: Object,
};
export default function Home(props: Props) {
  return (
    <>
      <Head>
        <title>RealTech247 | Tìm nhà chính chủ</title>
        <link rel="preload" href="/images/banner.webp" as="image" />
      </Head>
      <NextSeo
        title="REALTECH247 | Nền tảng bất động sản công nghệ"
        robotsProps={{
          nosnippet: true,
          notranslate: true,
          noimageindex: true,
          noarchive: true,
          maxSnippet: -1,
          maxImagePreview: 'none',
          maxVideoPreview: -1,
        }}
      />
      <HomeContext.Provider value={{ ...props }}>
        <HomePage />
      </HomeContext.Provider>
    </>
  );
}
export async function getStaticProps() {
  const apolloClient = initializeApollo();
  try {
    const { data: authorizeData } = await apolloClient.query({
      query: GET_REAL_ESTATE_BY_FIELD(fieldGetRealEstateBasic),
      variables: {
        page: 1,
        limit: 12,
        isAuthorizedRe: true,
      },
    });
    const { data: vip1Data } = await apolloClient.query({
      query: GET_REAL_ESTATE_BY_FIELD(fieldGetRealEstateBasic),
      variables: {
        page: 1,
        limit: 12,
        postType: ['1'],
      },
    });
    const { data: vip2Data } = await apolloClient.query({
      query: GET_REAL_ESTATE_BY_FIELD(fieldGetRealEstateBasic),
      variables: {
        page: 1,
        limit: 12,
        postType: ['2'],
      },
    });
    await apolloClient.query({
      query: LIST_COMPANY_BY_TYPE,
      variables: {
        type: 'estate',
        isPromoted: true,
        limit: 12,
      },
    });
    await apolloClient.query({
      query: GET_REPRESENTATIVE_BROKERS,
      variables: {
        limit: 12,
      },
    });
    const { data: projectData } = await apolloClient.query({
      query: GET_REAL_ESTATE_PROJECT_BY_FIELD(fieldsGetRealEstateProject),
      variables: {
        limit: 12,
      },
    });
    return addApolloState(apolloClient, {
      props: {
        authorizeData,
        vip1Data,
        vip2Data,
        projectData,
      },
      revalidate: 300,
    });
  } catch (e) {
    return addApolloState(apolloClient, {
      props: {},
    });
  }
}

Home.getLayout = (page) =>
  getLayout(page, { headerClass: 'fixed top-0 lg:static' });
