export const options = [
  {
    key: 'ForSale',
    value: 'Đang bán',
  },
  {
    key: 'ForRent',
    value: 'Cho thuê',
  },
  {
    key: 'Project',
    value: 'Dự án',
  },
  {
    key: 'ToBuy',
    value: 'Cần mua',
  },
  {
    key: 'ToRent',
    value: 'Cần thuê',
  },
];

export const optionsOnMap = [
  {
    key: 'ForSale',
    value: 'Đang bán',
  },
  {
    key: 'ForRent',
    value: 'Cho thuê',
  },
];
export const convertTypeHomeToProject = [
  'complex',
  'landProject',
  'industrialArea',
  'buildingProject',
  'shoppingMall',
];
export const convertTypeHomeToSale = [
  'townhouse',
  'villa',
  'building',
  'hotel',
  'warehouse',
  'land',
  'apartment',
  'shophouse',
  'penthouse',
  'condotel',
];
export const convertTypeHomeToRent = [
  'townhouse',
  'villa',
  'building',
  'hotel',
  'warehouse',
  'land',
  'apartment',
  'shophouse',
  'penthouse',
  'motel',
  'condotel',
];
export const utilitiesConfig = [
  {
    key: 'pool',
    value: 'Hồ bơi',
  },
  {
    key: 'storehouse',
    value: 'Nhà kho',
  },
  {
    key: 'garden',
    value: 'Sân vườn',
  },
  {
    key: 'lift',
    value: 'Thang máy',
  },
  {
    key: 'smartKey',
    value: 'Thẻ ra vào tòa nhà',
  },
];
export const hasAlleyConfig = [
  { key: '', value: 'Tất cả' },
  { key: 'no', value: 'Mặt tiền' },
  { key: 'yes', value: 'Trong hẻm' },
];
export const defaultConfigs = {
  isForSell: 'ForSale',
  searchString: '',
  province: '',
  district: '',
  ward: '',
  street: '',
  areaKey: '',
  areaTo: 20001,
  areaFrom: 0,
  priceKey: '',
  priceFrom: 0,
  priceTo: 101,
  hasAlley: '',
  widthKey: '',
  widthFrom: 0,
  widthTo: 101,
  lengthKey: '',
  lengthFrom: 0,
  lengthTo: 501,
  rearKey: '',
  rearFrom: 0,
  rearTo: 101,
  bedroomCountKey: '',
  bedroomCountFrom: 0,
  bedroomCountTo: 169,
  toiletCountKey: '',
  toiletCountFrom: 0,
  toiletCountTo: 169,
  floorCountKey: '',
  floorCountFrom: 0,
  floorCountTo: 169,
  realEstateType: ['townhouse'],
  utilities: ['pool', 'garden', 'lift', 'smartKey', 'storehouse'],
};
