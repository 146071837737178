import styles from '@styles/components/Home/Home.module.scss';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
const Male = dynamic(() => import('@public/svg/male.svg'), { ssr: false });
const Phone = dynamic(() => import('@public/svg/phone.svg'), { ssr: false });
const IconBroker = dynamic(() => import('@public/svg/broker.svg'), {
  ssr: false,
});

const TitleNavigation = dynamic(() => import('@components/titleNavigation'), {
  ssr: false,
});
const CarouselV2 = dynamic(() => import('@components/CarouselV2'), {
  ssr: false,
});

type Props = {
  data?: Array,
};

export default function Broker(props: Props) {
  const { data = [] } = props;
  const router = useRouter();
  return (
    <div
      className={`${styles.session} ${data?.length > 0 ? 'flex' : 'hidden'}`}>
      <TitleNavigation
        title="Nhà môi giới tiêu biểu"
        Icon={IconBroker}
        iconClassName="w-27px"
        showViewAll={data?.length > 0}
        onPressViewAll={async () => await router.push('/404')}
      />
      <div className={`w-full ${styles.realEstateCarousel}`}>
        <CarouselV2
          breakpoints={{
            320: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 9,
            },
            1024: {
              slidesPerView: 6,
              slidesPerGroup: 6,
              spaceBetween: 30,
            },
          }}
          showNavigation
          data={data}
          RenderItem={({ index, item }) => (
            <ItemBroker
              onPress={() => alert('Chức năng đang được hoàn thiện')}
              key={index}
              {...item}
            />
          )}
        />
      </div>
    </div>
  );
}

type ItemBrokerProps = {
  avatar?: Object,
  fullName?: string,
  phone?: string,
  onPress?: Function,
};

function ItemBroker(props: ItemBrokerProps) {
  const { avatar, fullName, phone, onPress } = props;
  return (
    <div onClick={onPress} className={styles.broker}>
      <div className={styles.imageBroker}>
        <div
          className={`${
            avatar?.originalUrl?.length > 0 ? 'flex' : 'hidden'
          } w-full h-full rounded-rd4px bg-no-repeat bg-center bg-cover`}
          style={{
            backgroundImage: `url('${avatar?.originalUrl}')`,
          }}
        />
        <Male
          className={`${
            avatar?.originalUrl?.length > 0 ? 'hidden' : 'block'
          } w-full h-full rounded-rd-4px`}
        />
      </div>
      <div className="flex flex-1 flex-col justify-between mt-8px">
        <div className={styles.fullNameBroker}>{fullName}</div>
        <a className={styles.contactButton} href={`tel:+${phone}`}>
          <Phone className={styles.iconPhone} />
          <div className={`${styles.contactText}`}>Liên hệ</div>
        </a>
      </div>
    </div>
  );
}
