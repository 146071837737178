export const fieldsGetRealEstateProject = [
  'saveItem { id note belongsToCollection { id } }',
  'fullAddress',
  'projectName',
  'isSaved',
  'demandEstate',
  'type',
  'mediaUrlsIds',
  'mediaUrls { ...FileAllFields}',
];

export const fieldGetRealEstateBasic = [
  'realEstateId',
  'demandEstate',
  'realEstateType',
  'type',
  'isForSell',
  'area',
  'width',
  'length',
  'bedroomCount',
  'toiletCount',
  'floorCount',
  'price',
  'priceUnit',
  'postTitle',
  'postType',
  'mediaUrls { ...FileAllFields2 }',
  'mediaUrlsIds',
  'isAuthorizedRe',
  'createdAt',
  'fullAddress',
];

export const fieldGetRealEstate = [
  'realEstateId',
  'demandEstate',
  'realEstateType',
  'type',
  'isForSell',
  'area',
  'width',
  'length',
  'bedroomCount',
  'toiletCount',
  'floorCount',
  'price',
  'priceUnit',
  'postTitle',
  'postType',
  'mediaUrls { ...FileAllFields2 }',
  'mediaUrlsIds',
  'isAuthorizedRe',
  'saveItem { id note belongsToCollection { id } }',
  'createdAt',
  'isSaved',
  'privateNote',
  'fullAddress',
  'postStatus',
];

export const fieldNeedRealEstate = [
  'isNeedToBuy',
  'type',
  'demandEstate',
  'mediaUrlsIds',
  'realEstateId',
  'price { key value from to unit }',
  'area { key value from to unit }',
  'bedroomCount { key value from to unit }',
  'toiletCount { key value from to unit }',
  'floorCount { key value from to unit }',
  'postTitle',
  'postType',
  'saveItem { id note belongsToCollection { id } }',
  'privateNote',
  'mediaUrls { ...FileAllFields2 }',
  'isSaved',
  'createdAt',
  'postStatus',
];
