import { FormProvider, useForm } from 'react-hook-form';
import AdvanceDropdown from '@components/AdvanceSearch/components/AdvanceDropdown';
import RenderRow from '@components/AdvanceSearch/components/RenderRow';
import TypeOfDemandButton from '@components/AdvanceSearch/components/TypeOfDemandButton';
import styles from '@styles/components/AdvanceSearch/AdvanceSearch.module.scss';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { defaultConfigs } from '@components/AdvanceSearch/configs';
import useWindowScroll from 'react-use/lib/useWindowScroll';
import { interpolate } from '@utils/helper';
type AdvanceSearchProps = {
  wrapperClassName?: string,
};
export default function AdvanceSearch({
  wrapperClassName,
}: AdvanceSearchProps) {
  const form = useForm({
    defaultValues: {
      ...defaultConfigs,
    },
  });
  const router = useRouter();
  const [expand, setExpand] = useState(false);
  const { y } = useWindowScroll();
  const color = interpolate(y, {
    inputRange: [0, 40],
    outputRange: ['transparent', '#2459ad'],
  });
  const borderRadiusTop = interpolate(y, {
    inputRange: [0, 40],
    outputRange: [9, 0],
  });
  const borderTopWidth = interpolate(y, {
    inputRange: [0, 40],
    outputRange: [1, 0],
  });
  return (
    <FormProvider {...form}>
      <div
        style={{
          backgroundColor: router.pathname !== '/' ? '#fff' : '#0D3C9650',
          borderColor: router.pathname !== '/' ? '#2459ad' : 'transparent',
          top: 64,
          zIndex: 1000,
          paddingLeft: router.pathname !== '/' ? 8 : 24,
          paddingRight: router.pathname !== '/' ? 8 : 24,
          paddingTop: router.pathname !== '/' ? 16 : 24,
          paddingBottom: router.pathname !== '/' ? 16 : 24,
          borderRadius: router.pathname !== '/' ? 0 : 9,
          boxShadow: router.pathname !== '/' && `0px 10px 10px -10px ${color}`,
          borderTopLeftRadius: router.pathname !== '/' ? borderRadiusTop : 9,
          borderTopRightRadius: router.pathname !== '/' ? borderRadiusTop : 9,
          borderBottomLeftRadius: 9,
          borderBottomRightRadius: 9,
          borderWidth: router.pathname !== '/' ? 1 : 0,
          borderTopWidth: router.pathname !== '/' ? borderTopWidth : 0,
        }}
        className={`w-full space-y-6 hidden lg:flex flex-col ${wrapperClassName}`}>
        {router.pathname === '/' && <TypeOfDemandButton />}
        <div className="flex flex-row space-x-5">
          <AdvanceDropdown />
          {router.pathname !== '/' && (
            <button
              onClick={() => setExpand(!expand)}
              className={styles.moreButton}>
              <img alt="filter" src="/svg/filter_blue.svg" />
              <div className="text-15 text-blue-main font-bold">
                {expand ? 'Thu gọn' : 'Lọc thêm'}
              </div>
            </button>
          )}
        </div>
        <RenderRow expand={expand} />
      </div>
    </FormProvider>
  );
}
